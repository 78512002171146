export const productsData = [
    {
        name: "Abridor de Bobina e Embalagem",
        oldprice: "R$ 105.00",
        value: "R$ 95.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/10/cortador-de-bobina-site.png",
    },
    {
        name: "Alinhador de Tubo PEAD Articulado Régua",
        value: "R$ 35.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador_articulado-regua_tubo-pead-63-250-2.jpg",
    },
    {
        name: "Alinhador de Tubo PEAD com Base Universal",
        oldprice: "R$ 25.00",
        value: "R$ 15.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador-de-tubo-pead-modelo-base-universal.jpg",
    },
    {
        name: "Alinhador de Tubo Pead Multidimensional",
        value: "R$ 135.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2023/08/alinhador-de-tubo-pead-site.png",
    },
    {
        name: "Alinhador de Tubo PEAD para Luva",
        value: "R$ 2.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador-de-tubo-pead-luva-site-2.jpg",
    },
    {
        name: "Alinhador para Tubo PEAD 45°",
        oldprice: "R$ 80.00",
        value: "R$ 62.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2024/07/alinhador-de-tubo-pead-45-graus.png",
    },
    {
        name: "Abridor de Bobina e Embalagem",
        oldprice: "R$ 105.00",
        value: "R$ 95.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/10/cortador-de-bobina-site.png",
    },
    {
        name: "Alinhador de Tubo PEAD Articulado Régua",
        value: "R$ 35.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador_articulado-regua_tubo-pead-63-250-2.jpg",
    },
    {
        name: "Alinhador de Tubo PEAD com Base Universal",
        oldprice: "R$ 25.00",
        value: "R$ 15.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador-de-tubo-pead-modelo-base-universal.jpg",
    },
    {
        name: "Alinhador de Tubo Pead Multidimensional",
        value: "R$ 135.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2023/08/alinhador-de-tubo-pead-site.png",
    },
    {
        name: "Alinhador de Tubo PEAD para Luva",
        value: "R$ 2.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2022/08/alinhador-de-tubo-pead-luva-site-2.jpg",
    },
    {
        name: "Alinhador para Tubo PEAD 45°",
        oldprice: "R$ 80.00",
        value: "R$ 62.00",
        image: "https://sulbras.ind.br/wp-content/uploads/2024/07/alinhador-de-tubo-pead-45-graus.png",
    },
]