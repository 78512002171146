import React, { useState, useEffect } from 'react';
import { productsData } from './data/productsData';
import './App.css';
import Header from './images/sulbras.png';

function App() {
  return (
    <div className='index-page'>
      <img src={Header}></img>
      <div className='products'>
      {
        productsData.map((products) => (
          <div key={products.name} className='products-item'>
              <img src={ products.image } alt="" />
              <div className='description-product'>
                <h4 className='title-product'>{ products.name }</h4>
                <div className='price-content'>
                  <p className='old-price'>{ products.oldprice }</p>
                  <p className='price-product'>{ products.value }</p>
                </div>
                <a className='button' href='#'>Adicionar ao Carrinho</a>
              </div>
          </div>
        ))
      }
      </div>
    </div>
  );
}

export default App;
